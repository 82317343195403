<div class="alerts-container">
    <div id="portfolioSummary" class="main-card" [ngClass]="{'alertsDisplayed': openCard}" #content="pwContent" pwContent>
        <div class="bottom-row" (click)="viewAlerts()">
            <div class="alerts-section">
                <div id="alert-list" class="alert-block">
                    <span class="alert-caution"></span>
                    <span class="alert-title" [innerHTML]="content['PWOverview_PortfolioAlerts']"></span>
                    <span class="alert-list-length">({{alertList.length}})</span>
                </div>

                <div class="showMoreAlerts">
                    <button class="navigateLink toggleAlerts" *ngIf="!openCard && alertList.length > 0">
                        <span class="icon icon-down-arrow-red"></span>
                    </button>
                    <button class="navigateLink toggleAlerts" *ngIf="openCard && alertList.length > 0">
                        <span class="icon icon-up-arrow-red"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="alert-card" *ngIf="openCard && alertList.length > 0">
        <button class="alert-row box" *ngFor="let alert of alertList" (click)="navigateToAlertPage(alert.url)">
            <div class="alert-row alert-details-container">
                <div class="alert-details">
                    <span class="status">{{alert.status}}:</span>
                    <ng-container *ngIf="alert.shortMessage">
                        <span class="message" [innerHTML]="content[alert.shortMessage]"></span>
                    </ng-container>
                </div>
            </div>
            <div class="alert-icons">
                <button class="close" aria-label="Remove alert button" (click)="removeAlert(alert)"></button>
            </div>
        </button>
    </div>
</div>
<div class="alerts-container-print" *ngIf="alertList.length > 0">
    <div id="portfolioSummaryPrint" class="main-card" [ngClass]="{'alertsDisplayed': openCard}" #content="pwContent" pwContent>
        <div class="bottom-row">
            <div class="alerts-section">
                <div id="alert-list-print" class="alert-block">
                    <span class="alert-caution"></span>
                    <span class="alert-title" [innerHTML]="content['PWOverview_PortfolioAlerts']"></span>
                    <span class="alert-list-length">({{alertList.length}})</span>
                </div>
            </div>
        </div>
    </div>
    <div class="alert-card">
        <div class="alert-row box" *ngFor="let alert of alertList">
            <div class="alert-row alert-details-container">
                <div class="alert-details">
                    <span class="status">{{alert.status}}:</span>
                    <ng-container *ngIf="alert.shortMessage">
                        <span class="message" [innerHTML]="content[alert.shortMessage]"></span>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
