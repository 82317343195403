<mat-card #content="pwContent" pwContent>
    <mat-card-header>
        <div class="titleWrapper">
            <mat-card-title>
                <span *ngIf="!isHypotheticalData" class="center-vertically" [innerHTML]="content['TesterTool_HypotheticalBondAnalysis_DialogTitle']"></span>
                <span *ngIf="isHypotheticalData" class="center-vertically" [innerHTML]="content['TesterTool_Overview_HypotheticalBondCard_Title']"></span>
                <button class="icon icon-question-mark-black " (click)="selectSection('Bond analysis')"></button>
            </mat-card-title>
            <mat-card-actions>
                <button *ngIf="hasBonds" class="navigateLink seeDetails" aria-label='Bond Analysis see details' (click)="goToBondAnalysisPage()">
                    <span [innerHTML]="content['PWOverview_BondAnalysis_SeeDetails']"></span><i class="icon icon-right-arrow-blue-modernized"></i>
                </button>
            </mat-card-actions>
        </div>
        <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p *ngIf="!hasBonds; else bonds;" [innerHTML]="content[contentNumber]"></p>
        <ng-template #bonds>
            <div [ngClass]="{'isHypothetical': isHypotheticalData}" class="modernizedContainer">
                <div class="cardChart">
                    <PW1-donut-chart-component [id]="chartId" [dataSet]="bondAnalysisChartData" [height]="188" [width]="188"></PW1-donut-chart-component>
                </div>
                <div class="cardLegend">
                    <PW1-chart-legend [legendArray]="legendArrayModern" [legendHeader]="legendHeader"></PW1-chart-legend>
                </div>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>
