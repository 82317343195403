<div #content="pwContent" pwContent class="modernizedRiskAnalysisWrapper" *ngIf="hasLoadedHistoricRiskReturnData">
    <div class="currentColumn">
        <mat-card-subtitle class="modernizedHeader">
            <span class="center-vertically" [innerHTML]="content['PWAsset_DetailsCard_Accordion_CurrentAssetAllocation']"></span>
            <button class="icon icon-question-mark-black" (click)="selectSection('Risk-return mix analysis')"></button>             
        </mat-card-subtitle>
        <mat-card-subtitle class="modernizedHistoricalRiskReturn" [innerHTML]="content['PWAsset_DetailsCard_Accordion_CurrentHistoricalRiskReturn'] + ' (' + currentAllocationHistoricRiskReturn.fromYear + '-' + currentAllocationHistoricRiskReturn.toYear + ')'" ></mat-card-subtitle>
        <div class='modernizedRiskReturnWrapper'>
            <div class='boxItem'><span class="modernizedAverageValues">{{currentAllocationHistoricRiskReturn.averageReturn + '% '}}</span><span class="modernizedAverageContent">{{content['PWAsset_DetailsCard_Accordion_CurrentAverageReturn']}}</span></div>
            <div class='boxItem'><span class="modernizedAverageValues">{{currentAllocationHistoricRiskReturn.bestYearAverage + '% '}}</span><span class="modernizedAverageContent">{{content['PWAsset_DetailsCard_Accordion_CurrentBestYear'] + ' (' + currentAllocationHistoricRiskReturn.bestYear + ')'}}</span></div>
            <div class='boxItem'><span class="modernizedAverageValues">{{currentAllocationHistoricRiskReturn.worstYearAverage + '% '}}</span><span class="modernizedAverageContent">{{content['PWAsset_DetailsCard_Accordion_CurrentWorstYear'] + ' (' + currentAllocationHistoricRiskReturn.worstYear + ')'}}</span></div>
            <div class='boxItem'><span class="modernizedAverageValues">{{currentAllocationHistoricRiskReturn.percentYearsOfLoss + '% '}}</span><span class="modernizedAverageContent">{{content['PWAsset_DetailsCard_Accordion_CurrentYearsWithALoss'] + ' (' + currentAllocationHistoricRiskReturn.yearsWithLoss + ' of ' + currentAllocationHistoricRiskReturn.totalYears + ')'}}</span></div>
        </div>
    </div>
    <div class="targetColumn">
        <mat-card-subtitle class="modernizedHeader" [innerHTML]="content['PWAsset_DetailsCard_Accordion_TargetAssetAllocation']"></mat-card-subtitle>
        <mat-card-subtitle class="modernizedHistoricalRiskReturn" [innerHTML]="content['PWAsset_DetailsCard_Accordion_TargetHistoricalRiskReturn'] + ' (' + targetAllocationHistoricRiskReturn.fromYear + '-' + targetAllocationHistoricRiskReturn.toYear + ')'"></mat-card-subtitle>
        <div class='modernizedRiskReturnWrapper'>
            <div class='boxItem'><span class="modernizedAverageValues">{{targetAllocationHistoricRiskReturn.averageReturn + '% '}}</span><span class="modernizedAverageContent">{{content['PWAsset_DetailsCard_Accordion_TargetAverageReturn']}}</span></div>
            <div class='boxItem'><span class="modernizedAverageValues">{{targetAllocationHistoricRiskReturn.bestYearAverage + '% '}}</span><span class="modernizedAverageContent">{{content['PWAsset_DetailsCard_Accordion_TargetBestYear'] + ' (' + targetAllocationHistoricRiskReturn.bestYear + ')'}}</span></div>
            <div class='boxItem'><span class="modernizedAverageValues">{{targetAllocationHistoricRiskReturn.worstYearAverage + '% '}}</span><span class="modernizedAverageContent">{{content['PWAsset_DetailsCard_Accordion_TargetWorstYear'] + ' (' + targetAllocationHistoricRiskReturn.worstYear + ')'}}</span></div>
            <div class='boxItem'><span class="modernizedAverageValues">{{targetAllocationHistoricRiskReturn.percentYearsOfLoss + '% '}}</span><span class="modernizedAverageContent">{{content['PWAsset_DetailsCard_Accordion_TargetYearsWithALoss'] + ' (' + targetAllocationHistoricRiskReturn.yearsWithLoss + ' of ' + targetAllocationHistoricRiskReturn.totalYears + ')'}}</span></div>
        </div>
    </div>
</div>
