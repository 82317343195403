import { production, external } from './environment.production';

export const environment = {
  ...production,
  ...external,
  isExternal: 'true',
  HOST: 'personal.vanguard.com',
  chartDataUrlEcs:
    external.RegionlessHostUrl +
    '/pw2-portfolio-watch-webservice/portfoliowatch/chart-data?poid=VG-CLIENT-POID&viewID=VIEWID&accountGroup=ACCOUNTGROUP',
  securityDataUrlEcs:
    external.RegionlessHostUrl + '/pw2-portfolio-watch-webservice/portfoliowatch/valid-security?',
  hypotheticalDataUrlEcs:
    external.RegionlessHostUrl +
    '/pw2-portfolio-watch-webservice/portfoliowatch/hypothetical-data?poid=VG-CLIENT-POID&viewID=VIEWID&accountGroup=ACCOUNTGROUP',
  targetMixRiskReturnUrl:
    external.RegionlessHostUrl +
    '/pw2-portfolio-watch-webservice/portfoliowatch/target-risk-return',
  GraphQLUrl: 'https://apps.ecs.retp.c1.vanguard.com/xs1-secure-site-consumer-api/graphql',
  saveTargetMixUrl:
    external.RegionlessHostUrl + "/ag3-account-grouping-consumer-api/target-allocation/create",
  updateTargetMixUrl:
    external.RegionlessHostUrl + "/ag3-account-grouping-consumer-api/target-allocation/edit",
  LOGGER_URL: external.RegionlessHostUrl + '/pw2-portfolio-watch-webservice/log',
  AEMContentUrl:
    'https://personal1.vanguard.com/content/retail-headless/portfolio-analysis/portfolio-analysis/content.model.json.json',
  AEMHelpContentUrl:
    'https://personal1.vanguard.com/content/retail-headless/portfolio-analysis/portfolio-analysis/portfolio-analysis-help.model.json.json',
  deployment: 'production',
  CONSUMER_API: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/',
  LOGON_URL: 'https://logon.vanguard.com/logon',
  TEALEAF_LIB: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/tealeaf',
  IDLE_TIMER_CONFIG: JSON.stringify({
    idleTime: 900000,
    idleWarningTime: 180000,
    absoluteWarningTime: 180000,
    logOffRedirectURL: 'https://logon.vanguard.com/logon/idlelogoff',
    idleSessionRefreshURL: 'https://ccc.vanguard.com/api/idle-timeout-refresh',
    extendedUserToggleEnabled: true,
    clientServiceURL: 'https://personal1.vanguard.com/laa-ret-etm-adobe-launch-webapp/extendedUser',
  }),
  IDLE_TIMER_URL_CSS: 'https://ccc.vgcontent.info/vg-idle-timer/latest/css/idle_timer.min.css',
  IDLE_TIMER_URL_JS: 'https://ccc.vgcontent.info/vg-idle-timer/latest/js/idle_timer.min.js',
  ADOBE_LAUNCH_JS:
    'https://corp.etm.assets.vgdynamic.info/files/retail_global_prd/379f26abac75/9d4ec58961b2/launch-ecb7d9153f04.min.js',
  ADOBE_TARGET_ANTIFLICKER_JS:
    'https://corp.at2.assets.vgdynamic.info/files/js/core/antiflicker.min.js',
  ADOBE_DATA_LAYER:
    'https://personal1.vanguard.com/laa-ret-etm-adobe-launch-webapp/static/adobe-data-layer.js',
  APPDYNAMICS_CONFIG: JSON.stringify({
    appKey: 'AD-AAB-ABU-ESA',
    adrumExtUrl: 'https://ccc.vgcontent.info/vg-app-dynamics/2.0.0/js',
    spa: { spa2: true },
    xd: { enable: false },
  }),
  CCC_APPDYNAMICS_JS: 'https://ccc.vgcontent.info/vg-app-dynamics/2.0.0/js/app_dynamics.min.js',
  GATEKEEPER_PW2_API:
    external.RegionlessHostUrl +
    '/pw2-portfolio-watch-webservice/portfoliowatch/toggle-switch?toggleId={{toggleid}}',
  ModernizedManageGroupApp: 'https://portfoliowatch-views.web.vanguard.com/managegroups',
  FOCUS_PANEL_ELEMENT_JS: 'https://focus-panel.web.vanguard.com/focus-panel-element.js?cache=false',
  c3Source: "https://mc3-static-assets.web.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.js",
  c3Config: `const cccConfig = {
    site: 'retailExternal' ,
    rte: 'PROD',
    app: 'PW1',
    concerns: 'al,adl,it,csp,adv4',
    appConfig: {
      csp: {
        'img-src': '*.vgdynamic.info *.mypurecloud.com wss://*.mypurecloud.com',
        'script-src': '*.kampyle.com *.tt.omtrdc.net *.mypurecloud.com wss://*.mypurecloud.com',
        'style-src': '*.tt.omtrdc.net *.mypurecloud.com wss://*.mypurecloud.com',
        'connect-src': '*.vanguard.com *.tt.omtrdc.net *.mypurecloud.com wss://*.mypurecloud.com',
      },
    }
  };

  window.c3bundle && c3bundle.loadC3Bundle(cccConfig);`,
  VGN_HEADER_FOOTER_JS: 'https://n4v.web.vanguard.com/secure-site-components.js',
  VGN_HEADER_FOOTER_STYLE: 'https://n4v.web.vanguard.com/nav-preload.css'
};
