<div #content="pwContent" pwContent class="tableComponentWrapper">
    <PW1-alert-notifications [pageName]="'/costs/mutual-fund-costs'"></PW1-alert-notifications>
    <div class="tableHeaderWrapper">
        <div class='tableTitle'>
            <span [innerHTML]="content['PWCosts_DetailsCard_MutualFundCosts_MutualFundCosts']"></span>
            <button class="icon icon-question-mark-black" (click)="openMutualFundDrawer()"></button>
            <PW1-export-to-csv
                    [parsableArray]="generateTableDataModernized()"
                    [fileName]="'MutualFundCostData'" [modernized]="true"></PW1-export-to-csv>
        </div>
    </div>
    <ng-container *ngIf="loaded">
        <ng-container *ngFor="let acct of tableData">
            <h4 class="accountHeader" >{{acct.accountName}}</h4>
            <table role="presentation" class="c11n-table c11n-table--medium table holdingsTable">
                <thead>
                <tr class="c11n-table__tr">
                  <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left ticker"><strong>{{content['PWCosts_DetailsCard_MutualFundCosts_Table_Symbol']}}</strong></th>
                  <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right name"><strong>{{content['PWCosts_DetailsCard_MutualFundCosts_Table_Name']}}</strong></th>
                  <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right value"><strong>{{content['PWCosts_DetailsCard_MutualFundCosts_Table_Value']}}</strong></th>
                  <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right expenseRatio"><strong>{{content['PWCosts_DetailsCard_MutualFundCosts_Table_ExpenseRatio']}}</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr class="c11n-table__tr" *ngFor="let holding of acct.holdings">
                  <td scope="row" *ngIf="holding.ticker" class="c11n-table__td c11n-table__tbody-th c11n-table__td--align-left ticker">{{holding.ticker}}</td>
                  <td class="c11n-table__td c11n-table__td--align-right name">{{holding.fundName}}</td>
                  <td *ngIf="holding.fundBalance" class="c11n-table__td c11n-table__td--align-right value">{{holding.fundBalance | currency}}</td>
                  <td  *ngIf="holding.fundBalance" class="c11n-table__td c11n-table__td--align-right expenseRatio">{{holding.categorization.expenseRatio.toFixed(2)}}</td>
                </tr>
              </tbody>
              </table>
        </ng-container>
    </ng-container>
    <ng-template>
        <tr class="tableHeaderRow">
            <th class="ticker" [innerHTML]="content['PWCosts_DetailsCard_MutualFundCosts_Table_Symbol']"></th>
            <th class="name" [innerHTML]="content['PWCosts_DetailsCard_MutualFundCosts_Table_Name']"></th>
            <th class="value" [innerHTML]="content['PWCosts_DetailsCard_MutualFundCosts_Table_Value']"></th>
            <th class="expenseRatio" [innerHTML]="content['PWCosts_DetailsCard_MutualFundCosts_Table_ExpenseRatio']"></th>
        </tr>
    </ng-template>
</div>
