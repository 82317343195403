<mat-card id="stockAnalysis" #content="pwContent" pwContent><mat-card-header>
        <div class="titleWrapper">
            <mat-card-title>
                <span *ngIf="!isHypotheticalData" class="center-vertically" [innerHTML]="content['PWOverview_StockAnalysis']"></span>
                <span *ngIf="isHypotheticalData" class="center-vertically" [innerHTML]="content['TesterTool_Overview_HypotheticalStockCard_Title']"></span>
                <button class="icon icon-question-mark-black " (click)="selectSection('Stock analysis')"></button>
            </mat-card-title>
            <mat-card-actions>
                <button *ngIf="hasStocks" class="navigateLink seeDetails" aria-label='Stock Analysis see details' (click)="goToStockAnalysisPage()">
                    <span [innerHTML]="content['PWOverview_StockAnalysis_SeeDetails']"></span><i class="icon icon-right-arrow-blue-modernized"></i>
                </button>
            </mat-card-actions>
        </div>
        <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p *ngIf="!hasStocks; else stocks;" [innerHTML]="content[contentNumber]"></p>
        <ng-template #stocks>
            <div [ngClass]="{'isHypothetical': isHypotheticalData}" class="modernizedContainer">
                <div class="cardChart">
                    <!-- Remove comment after adding donut chart component -->
                    <PW1-donut-chart-component [id]="chartId" [dataSet]="stockAnalysisChartData" [height]="188" [width]="188"></PW1-donut-chart-component>
                </div>
                <div class="cardLegend">
                    <PW1-chart-legend [legendArray]="legendArrayModern" [legendHeader]="legendHeader">
                    </PW1-chart-legend>
                </div>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>
