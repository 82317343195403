<div class="target-asset-mix-modal" #content="pwContent" pwContent>
    <button class="changeTargetAssetLink" aria-label='Set target Asset mix'
            (click)="openDialog($event)">
        <span>{{content['PWOverview_SetTargetAssetMix']}}</span><i class="icon icon-right-arrow-blue-modernized"></i>
    </button>
    <c11n-modal-dialog #targetAssetMixModal [headingText]="content['PWTargetAssetMixModal_SetYourTargetAssetMix'] + accountGroup.GroupName"
                       [primaryButtonLabel]="content['PWTargetAssetMixModal_Continue']" (primaryClick)="navigateToTargetAssetMixPage()">
        <div c11n-modal-dialog-body>
            <div class="modalDisclaimer">
                <b>{{content['PWTargetAssetMixModal_Note']}}</b>
                {{content['PWTargetAssetMixModal_Disclaimer1']}}
                <a c11n-link tabindex="0"
                   (click)="navigateToAboutPage()"
                   [variant]="'primary-reinforced'"
                   class="cursor-pointer"
                   [fontWeight]="'bold'">{{content['PWTargetAssetMixModal_ReviewConsiderations']}}
                </a>
                {{content['PWTargetAssetMixModal_Disclaimer2']}}
            </div>
                <div class="radioButtonContainer">
                <c11n-radio class="c11n-radio-label-style" (stateChange)="chooseTargetAssetMixRoute('chooseAssetMix')" [labelText]="content['PWTargetAssetMixModal_ChooseAnAssetMix']">
                    <label for="radio1"></label><input c11nRadioInput id="radio1" type="radio" name="choices" value="chooseAssetMix" />
                </c11n-radio>
                <span class="radioButtonDescription">{{content['PWTargetAssetMixModal_UseOurSelectionTool']}}</span>
            </div>
            <div class="radioButtonContainer">
                <c11n-radio class="c11n-radio-label-style" (stateChange)="chooseTargetAssetMixRoute('suggestAssetMix')" [labelText]="content['PWTargetAssetMixModal_LetUsSuggestAnAssetMix']">
                    <label for="radio2"></label><input c11nRadioInput id="radio2" type="radio" name="choices" value="suggestAssetMix" />
                </c11n-radio>
                <span class="radioButtonDescription">{{content['PWTargetAssetMixModal_AnswerAFewQuestions']}}</span>
                </div>
        </div>
    </c11n-modal-dialog>
</div>
