<div id="appHeaderWrapper" class="app-header-wrapper" #content="pwContent" pwContent>
  <div class="secondary-cta-wrapper linkWrapper" *ngIf="!isUserInMainPage">
    <div class="app-nav-link c11n-text-md">
      <a
        c11n-link
        (click)="navigateBackToPortfolioWatch()"
        [fontWeight]="'bold'"
        [noWrap]="true"
        [iconName]="'link-left'"
        [iconPosition]="'leading'"
        class="button-cta"
        ><span [innerHTML]="content['PortfolioTester_BackToPortfolioWatch']"></span
      ></a>
    </div>

    <div class="hypothetical-button-wrapper" *ngIf="router.url.startsWith('/hypothetical')">
      <c11n-button
        class="hypothetical-button"
        [labelText]="content['TesterTool_HypotheticalOverview_CloseScenario']"
        (clickEvent)="navigateBackToPortfolioWatch()"
      ></c11n-button>
      <c11n-button
        class="hypothetical-button"
        [labelText]="content['TesterTool_HypotheticalOverview_EditScenario']"
        (clickEvent)="navigateToTesterPage()"
      ></c11n-button>
    </div>
  </div>

  <div
    id="primaryCTAWrapperExp"
    class="primary-cta-wrapper-exp"
    *ngIf="($isFailure | async) === false; else pwFailureBannerTitle"
  >
    <div
      class="page-title"
      *ngIf="!router.url.includes('tester-tool') && ($isLoading | async) !== true"
    >
      <span
        [innerHTML]="
          router.url.includes('/hypothetical')
            ? content['PortfolioTester_HypotheticalAnalysis'] + ' for ' + getDisplayName()
            : content[pageTitle] + ' for ' + getDisplayName()
        "
      >
      </span>
    </div>
    <div
      class="tools-section-exp-wrapper"
      *ngIf="!(router.url.includes('/tester-tool') || router.url.startsWith('/hypothetical'))"
    >
      <ng-template [ngTemplateOutlet]="toolsSection"></ng-template>
    </div>
  </div>

  <ng-template #pwFailureBannerTitle>
    <div
      class="page-title"
      *ngIf="!router.url.includes('tester-tool') && ($isLoading | async) !== true"
    >
      <span [innerHTML]="content[pageTitle]"> </span>
    </div>
  </ng-template>

  <div class="row">
    <div class="overview-content">
      <div *ngIf="!router.url.includes('/tester-tool')" class="overview">
        <div class="disclaimer" *ngIf="customizedAccountsView">
          <span [innerHTML]="content['PWHeader_CustomizedAccountsDisclaimer_1']"></span>
          <a
            c11n-link
            class="c11n-link c11n-link--secondary c11n-link--reinforced"
            [variant]="'secondary-reinforced'"
            [href]="
              '/us/XHTML/com/vanguard/retail/web/tpv_customview/view/TpvCustomView.xhtml'
                | domainHelper
            "
          >
            <span class="c11n-link__content">
              <span class="c11n-link__text">
                {{ content['PWHeader_CustomizedAccountsDisclaimer_CustomLink'] }}
              </span>
            </span>
          </a>
          <span [innerHTML]="content['PWHeader_CustomizedAccountsDisclaimer_2']"></span>
        </div>
      </div>
    </div>
  </div>

  <ng-template #toolsSection>
    <div
      id="headerToolsSection"
      class="tools-section"
      *ngIf="!router.url.startsWith('/hypothetical')"
    >
      <a
        c11n-link
        tabindex="0"
        (click)="openInlineDrawerPageHelp()"
        [variant]="'secondary-independent'"
        [fontWeight]="'normal'"
        (keyup.enter)="openInlineDrawerPageHelp()"
        aria-label="content['PWOverview_Help']"
        [iconName]="'help'"
        class="button-cta"
        >{{ content['PWOverview_Help'] }}
      </a>
      <a
        c11n-link
        id="printLink"
        target="_blank"
        tabindex="0"
        (click)="print(); printLinkClicked.emit(defaultData)"
        [variant]="'secondary-independent'"
        [fontWeight]="'normal'"
        (keyup.enter)="print(); printLinkClicked.emit(defaultData)"
        class="button-cta"
        >{{ content['PWOverview_Print'] }}
      </a>
      <a
        c11n-link
        tabindex="0"
        (click)="navigateToTesterPage()"
        [variant]="'secondary-independent'"
        [fontWeight]="'normal'"
        (keyup.enter)="navigateToTesterPage()"
        class="button-cta"
        >{{ content['PWOverview_PortfolioTester'] }}
      </a>
    </div>
  </ng-template>
</div>
