<div class="wrapper">
    <div class="currentContainer" #content="pwContent" pwContent>
        <PW1-alert-notifications [pageName]="'/bond-analysis/nine-box'"></PW1-alert-notifications>
        <div class="tableHeaderWrapper">
            <div class='tableTitle'>
                <span [innerHTML]="content['TesterTool_HypotheticalBondAnalysis_CurrentBondBoxStyle']"></span>
                <button class="icon icon-question-mark-black" (click)="openNineBoxDrawer()"></button>
            </div>
        </div>

        <div class="nineBoxContainer">
            <div class="leftBlock">
                <h3>
                    <span [innerHTML]="content['TesterTool_HypotheticalBondAnalysis_Credit_Quality']"></span>
                </h3>
            </div>
            <div class="rightBlock">
                <div class="gridContainer">
                    <div class="xHeaderGridItem">
                        <h3>
                            <span [innerHTML]="content['TesterTool_HypotheticalBondAnalysis_IR_Sensitivity']"></span>
                        </h3>
                        <div class="xGridContainer">
                            <div *ngFor="let content of xHeaders">
                                <div class="gridXItem">{{content}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="yHeaderGridItem">
                        <div *ngFor="let content of yHeaders">
                            <div class="gridYItem">{{content}}</div>
                        </div>
                    </div>
                    <div class="gridContentContainer">
                        <div *ngFor="let data of dataArray">
                            <div *ngFor="let content of data">
                                <div class="gridItem">{{content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hypotheticalContainer" #content="pwContent" pwContent>
        <PW1-alert-notifications [pageName]="'/bond-analysis/nine-box'"></PW1-alert-notifications>
        <div class="tableHeaderWrapper">
            <div class='tableTitle'>
                <span>Hypothetical bond 9-box style</span>
                <button class="icon icon-question-mark-black" (click)="openNineBoxDrawer()"></button>
            </div>
        </div>

        <div class="nineBoxContainer hypothetical">
            <div class="leftBlock">
                <h3>
                    <span [innerHTML]="content['TesterTool_HypotheticalBondAnalysis_Credit_Quality']"></span>
                </h3>
            </div>
            <div class="rightBlock">
                <div class="gridContainer">
                    <div class="xHeaderGridItem">
                        <h3>
                            <span [innerHTML]="content['TesterTool_HypotheticalBondAnalysis_IR_Sensitivity']"></span>
                        </h3>
                        <div class="xGridContainer">
                            <div *ngFor="let content of xHeaders">
                                <div class="gridXItem">{{content}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="yHeaderGridItem">
                        <div *ngFor="let content of yHeaders">
                            <div class="gridYItem">{{content}}</div>
                        </div>
                    </div>
                    <div class="gridContentContainer">
                        <div *ngFor="let data of hypotheticalArray">
                            <div *ngFor="let content of data">
                                <div class="gridItem">{{content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>