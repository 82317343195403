<div
  *ngIf="!isLoading"
  [ngClass]="{ fullWidth: isFullWidth, isHypothetical: isHypotheticalData }"
  #content="pwContent"
  pwContent
>
  <stacked-bar-chart
    [barChartData]="actualAssetMixChartData"
    [isFullWidth]="isFullWidth"
  ></stacked-bar-chart>
  <ng-container *ngIf="targetAssetMixChartData">
    <stacked-bar-chart
      [barChartData]="targetAssetMixChartData"
      [isFullWidth]="isFullWidth"
    ></stacked-bar-chart>
  </ng-container>
  <ng-container *ngIf="!targetAssetMixChartData">
    <div class="noTargetMixContainer">
      <span class="chartLabel">{{ content['PWOverview_AssetAllocation_Label_Target'] }}</span>
      <div class="noAssetMixWarning" *ngIf="!targetAssetMixChartData">
        <span
          class="warningLabel"
          [innerHTML]="content['PWOverview_AssetAllocation_NoTargetAllocation']"
        ></span>
        <div *ngIf="!isHypotheticalData && !isMFE && !isHypotheticalPage">
          <div>
            <set-target-asset-mix-modal></set-target-asset-mix-modal>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <table class="assetMixChartLegend">
    <tr class="headers">
      <th class="rowLabel">{{ content['PWOverview_AssetAllocation_Headers_Type'] }}</th>
      <th class="rowData">{{ content['PWOverview_AssetAllocation_Headers_Current'] }}</th>
      <th class="rowData">{{ content['PWOverview_AssetAllocation_Headers_Target'] }}</th>
      <th class="rowData">{{ content['PWOverview_AssetAllocation_Headers_Diff'] }}</th>
    </tr>
    <tr
      *ngFor="
        let actualAssetMixChartSlice of actualAssetMixChartData.chartSlices;
        let myIndex = index
      "
    >
      <td class="rowLabel">
        <svg height="12" width="12" class="display-svg">
          <circle
            cx="6"
            cy="6"
            r="6"
            attr.fill="{{ actualAssetMixChartData.chartSlices[myIndex].color }}"
          />
        </svg>
        <span>{{ actualAssetMixChartData.chartSlices[myIndex].label }}</span>
      </td>
      <td
        class="rowData"
        *ngIf="
          actualAssetMixChartData.chartSlices[myIndex].value === 0 &&
          actualAssetMixChartData.chartSlices[myIndex].nonZeroValue
        "
      >
        &lt;1%
      </td>
      <td
        class="rowData"
        *ngIf="
          actualAssetMixChartData.chartSlices[myIndex].value !== 0 ||
          !actualAssetMixChartData.chartSlices[myIndex].nonZeroValue
        "
      >
        {{ actualAssetMixChartData.chartSlices[myIndex].value }}%
      </td>
      <td class="rowData" *ngIf="targetAssetMixChartData">
        {{ targetAssetMixChartData.chartSlices[myIndex].value }}%
      </td>
      <td class="rowData" *ngIf="!targetAssetMixChartData">-</td>
      <td class="rowData" *ngIf="targetAssetMixChartData">
        <ng-container
          *ngIf="
            actualAssetMixChartData.chartSlices[myIndex].value -
              targetAssetMixChartData.chartSlices[myIndex].value >
            0
          "
          >+</ng-container
        >{{
          actualAssetMixChartData.chartSlices[myIndex].value -
            targetAssetMixChartData.chartSlices[myIndex].value
        }}%
      </td>
      <td class="rowData" *ngIf="!targetAssetMixChartData">-</td>
    </tr>
  </table>
  <div *ngIf="!isHypotheticalData && !isMFE && !isHypotheticalPage">
    <div>
      <set-target-asset-mix-modal></set-target-asset-mix-modal>
    </div>
  </div>
</div>
