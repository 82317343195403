<section #content="pwContent" pwContent id="costs-risk-content" tabindex="-1">
  <mat-card class="cardBody" id="test-mat-card">
    <mat-card-actions>
      <button class="navigateLink" aria-label="Back to overview" (click)="goBackToOverview()">
        <i class="icon icon-left-arrow-blue"></i>
        <span
          class="center-vertically"
          [innerHTML]="content['TesterTool_HypotheticalCosts_BackToOverview']"
        ></span>
      </button>
    </mat-card-actions>
    <div class="titleWrapper">
      <h2 class="pageTitle" [innerHTML]="content['TesterTool_HypotheticalCostsCard_Title']"></h2>
      <button
        class="icon icon-question-mark-black"
        (click)="selectSection('Investment costs')"
      ></button>
    </div>
    <mat-card-content class="mainCardContent">
      <account-group-assets></account-group-assets>
      <div class="bar-chart-wrapper">
        <div class="currentChart" *ngIf="hasLoadedChartData">
          <bar-chart
            [chartData]="chartDataModern"
            [industryChartId]="'testerToolCostPageActualIndustryChartId'"
            [vanguardChartId]="'testerToolCostPageActualVanguardChartId'"
            [yourCostsChartId]="'testerToolCostPageActualYourCostsChartId'"
            [isFullWidth]="false"
          ></bar-chart>
        </div>
        <div class="modernizedHypotheticalChart" *ngIf="hasLoadedHypotheticalChartData">
          <bar-chart
            [chartData]="hypotheticalChartDataModern"
            [industryChartId]="'testerToolCostPageHypotheticalIndustryChartId'"
            [vanguardChartId]="'testerToolCostPageHypotheticalVanguardChartId'"
            [yourCostsChartId]="'testerToolCostPageHypotheticalYourCostsChartId'"
            [isFullWidth]="false"
          >
          </bar-chart>
        </div>
      </div>
      <PW1-investment-costs-disclaimer-accordion></PW1-investment-costs-disclaimer-accordion>
      <PW1-hypothetical-cost-risk-tab-table
        class="cost-risk-tab-table"
      ></PW1-hypothetical-cost-risk-tab-table>
      <PW1-disclaimer
        contentTag="PWCosts_Disclaimer_Body"
        headerTag="PWCosts_Disclaimer_Header"
        linkTag="PWCosts_Disclaimer_Link_Vanguard"
      ></PW1-disclaimer>
    </mat-card-content>
  </mat-card>
</section>
