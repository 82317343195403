<div #content="pwContent" pwContent [ngClass]="{'fullWidth': isFullWidth}" class="bondDashboardWrapper">
    <h3>{{bondPercentage}}{{content['PWBond_DetailsCard_PercentBonds']}}
        <button class="icon icon-question-mark-black" (click)="openBondAnalysisDrawer()"></button>
    </h3>
    <stacked-bar-chart [barChartData]=actualBondChartData [isFullWidth]="isFullWidth"></stacked-bar-chart>
    <div class="chartLegend">
        <span *ngFor="let slice of actualBondChartData.chartSlices">
            <svg height="12" width="12">
                <circle cx="6" cy="6" r="6" attr.fill="{{slice.color}}" />
            </svg>
            <span>{{slice.label}}</span>
        </span>
        <a (click)="trackDomesticVsInternationalLink()" [routerLink]="'/bond-analysis/domestic-vs-international'">{{content['PWBond_DetailsCard_DomesticVsIntlHoldingsDetails']}} <i class="icon icon-right-arrow-blue-modernized"></i></a>
    </div>
</div>
