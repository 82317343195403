<div #content="pwContent" pwContent class="tableComponentWrapper">
  <PW1-alert-notifications [pageName]="'/bond-analysis/nine-box'"></PW1-alert-notifications>
  <div class="tableHeaderWrapper">
    <div class='tableTitle'>
      <span [innerHTML]="content['PWBond_DetailsCard_Bond9BoxStyle']"></span>
      <button class="icon icon-question-mark-black" (click)="openNineBoxDrawer()"></button>
    </div>
  </div>

  <div class="nineBoxContainer">
    <div class="leftBlock">
      <h3>
        <span [innerHTML]="content['PWBond_DetailsCard_Bond9BoxStyle_CreditQuality']"></span>
      </h3>
    </div>
    <div class="rightBlock">
      <div class="gridContainer">
        <div class="xHeaderGridItem">
            <h3>
              <span [innerHTML]="content['PWBond_DetailsCard_Bond9BoxStyle_InterestRateSensitivity']"></span>
            </h3>
          <div class="xGridContainer">
            <div *ngFor="let content of xHeaders">
              <div class="gridXItem">{{content}}</div>
            </div>
          </div>
        </div>
        <div class="yHeaderGridItem">
          <div *ngFor="let content of yHeaders">
            <div class="gridYItem">{{content}}</div>
          </div>
        </div>
        <div class="gridContentContainer">
          <div *ngFor="let data of dataArray">
            <div *ngFor="let content of data">
              <div class="gridItem">{{content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
